import React from 'react';
import PropTypes from 'prop-types';
import { 
  Typography,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel 
} from '@mui/material';

export default function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const renderSortLabel = (headCell) => {

    const disableSort = headCell.disableSort;

    // If disableSort, just render the label...
    if (disableSort) {
      return (
        <Typography style={{color: 'white'}}>
          {headCell.label}
        </Typography>
      )
    }

    // If not disableSort, render the label with a sort label...
    return (
      <TableSortLabel
        active={orderBy === headCell.id}
        direction={orderBy === headCell.id ? order : 'asc'}
        onClick={createSortHandler(headCell.id)}
        sx={{
          color: 'white',
          '&:hover': {
            color: 'white', // Hover state color
            '.MuiTableSortLabel-icon': {
                color: 'white', // Hover state color
                opacity: 1
            }
          },
          '.MuiTableSortLabel-icon': {
            '&:hover': {
              color: 'white', // Hover state color
              opacity: 1
            },
          },
          '&.Mui-active .MuiTableSortLabel-icon': {
            color: 'white', // Sorted state icon color
          }}}
      >
        <Typography style={{color: 'white'}}>
          {headCell.label}
        </Typography>
      </TableSortLabel>
    )
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => {
          if (headCell.hidden) {
            return undefined;
          }
          return (
            <TableCell
              key={headCell.id + index}
              align={'left'}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{width: headCell.columnWidth}}
            >
              {renderSortLabel(headCell)}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired
};
