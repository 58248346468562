import React, { useCallback, useContext, useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import TextField from '@mui/material/TextField'
import './Login.css'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import queryString from 'query-string'
import {
  BASE_SST_API_URL,
  SST_API_PATH_USERS_LOGIN,
  SST_API_PATH_USERS_LOGIN_SSO,
  SST_FEATURES,
} from '../../Constants'
import { SiteContext } from '../../Context'
import { isExpiredToken, isLoggedIn } from '../../shared/Utilities'
import { Typography } from '@mui/material'
import ProgressButton, {PROGRESS_BUTTON_VARIANTS} from '../../shared/components/ProgressButton/ProgressButton'
import SSTAlert from '../../shared/components/SSTAlert'
import translations from '../../translations/en.json'

import { useQueryClient } from 'react-query'
import useStyles from './Styles'
import GiantLogoPageTemplate from '../../shared/components/GiantLogoPageTemplate/GiantLogoPageTemplate'
import QuantifeelRouterLink from '../../shared/components/QuantifeelRouterLink/QuantifeelRouterLink'

const Login = (props) => {
  const history = useHistory()
  const location = useLocation()

  const queryClient = useQueryClient()
  const { login, userDetails } = useContext(SiteContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const classes = useStyles();

  useEffect(() => {
    document.title = 'Quantifeel - Login'
    //If we're already logged in, navigate right to the dashboard
    if (isLoggedIn(userDetails) && !isExpiredToken(userDetails) && !isLoading) {
      history.push('/dashboard')
    }
  }, [history, userDetails, isLoading])

  const updateEmail = (e) => {
    setEmail(e.target.value)
  }
  const updatePassword = (e) => {
    setPassword(e.target.value)
  }

  const attemptLogin = useCallback(async () => {
    setIsLoading(true)

    let response = ''
    let code = queryString.parse(location.search)?.code
    if (!!code) {
      if (SST_FEATURES.sso) {
        response = await fetch(
          BASE_SST_API_URL + SST_API_PATH_USERS_LOGIN_SSO + `?code=${code}`,
          {
            method: 'POST',
            body: JSON.stringify({}),
            headers: { 'Content-Type': 'application/json' },
          }
        )
      } else {
        setErrorMessage('SSO feature is not enabled')
        setIsLoading(false)
        return
      }
    } else {
      response = await fetch(BASE_SST_API_URL + SST_API_PATH_USERS_LOGIN, {
        method: 'POST',
        body: JSON.stringify({ emailAddress: email, password: password }),
        headers: { 'Content-Type': 'application/json' },
      })
    }
    const jsonResponse = await response.json()

    if (response.ok) {
      login(jsonResponse)

      //Decide where to send the user
      const qs = queryString.parse(location.search)
      if (!!qs.redirect) {
        history.push(decodeURIComponent(qs.redirect))
      } else {
        history.push('/dashboard')
      }
    } else {
      setErrorMessage(jsonResponse.message)
      setIsLoading(false)
    }
  }, [email, history, location?.search, login, password])

  useEffect(() => {
    // Need to reset queries on login page load. Doing this during logout
    // caused the customers query to be refired, resulting in QF-1645
    queryClient.removeQueries()
  }, [queryClient])

  useEffect(() => {
    let code = queryString.parse(location.search)?.code
    if (!!code) {
      attemptLogin()
    }
  }, [attemptLogin, location?.search])

  const submit = (e) => {
    e.preventDefault()
    attemptLogin()
  }

  return (
    <GiantLogoPageTemplate>

      <Form id="login-form" className={classes.form} onSubmit={submit}>
        <SSTAlert message={errorMessage} />
        <TextField
          className={classes.textField}
          label={'Email'}
          value={email}
          onChange={updateEmail}
          disabled={isLoading}
          autoFocus
          required
          inputProps={{ 'data-testid': 'login-email' }}
        />
        <TextField
          className={classes.textField}
          label={'Password'}
          value={password}
          onChange={updatePassword}
          disabled={isLoading}
          type="password"
          required
          inputProps={{ 'data-testid': 'login-password' }}
        />
        <ProgressButton
          className={classes.button}
          variant={PROGRESS_BUTTON_VARIANTS.ACTION}
          isLoading={isLoading}
          text={'Login'}
          data-testid="login-submit"
        />

        <QuantifeelRouterLink className={classes.link} to="/forgot-password">
          <Typography>{translations.pages.login.forgotPassword}</Typography>
        </QuantifeelRouterLink>

      </Form>

    </GiantLogoPageTemplate>
  )
}

export default withRouter(Login)
