import { makeStyles } from '@mui/styles'
import * as GreyScale from '@Theme/GreyScale'

const useStyles = makeStyles(theme => ({

  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    width: '100%',
  },
  tab:{
    flex: 1,
    height: 60,
    display: 'flex',
    maxWidth: 'none',
  },
  selectedTab: {
    backgroundColor: 'white',
  },
  tabPanel: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 26,
  },
  readOnlyNotificationContainer: {
    paddingBottom: 26,
  },
  tabPanelContentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',

    '& > *': {
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    }
  },
  settingCard: {
    minWidth: 360,
    maxWidth: 360,
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${GreyScale.GREY_3}`,
    borderRadius: 5,
    padding: theme.spacing(2),
  },
  settingCardTitleContainer: {
    // Default
  },
  settingCardTitle: {
    fontWeight: 400,
    lineHeight: `24px`,

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  settingCardRadioGroupContentContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  settingCardNumberAdjusterContentContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  radioGroup: {
    overflow: 'hidden',
  },
  formControlLabel: {
    margin: 0,

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '& > span:last-child': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
  },
  footerContentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerContentContainerSubContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(5),
    }
  },
  dialogActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',

    '& > :not(:last-child)': {
      marginRight: theme.spacing(2),
    }
  }
}));

export default useStyles
