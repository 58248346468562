import { makeStyles } from '@mui/styles'

const Styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    width: '100%'
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  table: {
    width: '100%',
    whiteSpace: 'nowrap',
  },
  tableContainer: {
    flexGrow: 1,
    borderTop: `1px solid #EAEAEA`,
  },
  progressIndicator: {
    margin: '20px auto',
  },
  tableData: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  noData: {
    width: 'min-content',
    whiteSpace: 'nowrap',
    margin: '10px auto',
  },
  waveformPopup: {
    cursor: 'pointer',
  },
  rightClickLinkStyle: {
    fontSize: '14px',
    lineHeight: '24px',
    color: 'grey',
    border: '1px solid black',
    borderRadius: '5px',
    padding: '10px',
    backgroundColor: 'white',
  },
  selectLabel: {
    margin: 0,
  },
}))

export default Styles
