import { makeStyles } from '@mui/styles'

const MOBILE_VIEW_THRESHOLD = 'lg' // https://mui.com/material-ui/customization/breakpoints/

const useStyles = makeStyles(theme => ({

    root: {
        display: 'flex',
        flexDirection: 'row',
        flex: 'auto',
        justifyContent: 'end',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: theme.palette.primary.main,

        [theme.breakpoints.down(MOBILE_VIEW_THRESHOLD)]: {
            height: 'unset',
            minHeight: '100vh',
            flexDirection: 'column',
            flexWrap: 'nowrap',
        }
    },
    logoContainer: {
        display: 'flex',
        flex: 2,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo: {
        objectFit: 'scale-down',
        maxWidth: 873,
        maxHeight: 187,
        width: '70%',
        height: '60%',

        [theme.breakpoints.down(MOBILE_VIEW_THRESHOLD)]: {
            width: '100%',
            height: '100%',
            padding: theme.spacing(4)
        }
    },
    contentContainer: {
        display: 'flex',
        height: '100%',
        width: '45%',
        flexDirection: 'column',
        background: 'white',

        [theme.breakpoints.down(MOBILE_VIEW_THRESHOLD)]: {
            width: '100%',
        }
    },
    innerContentContainer: {
        display: 'flex',
        flexGrow: 1,
        overflow: 'auto',

        [theme.breakpoints.down(MOBILE_VIEW_THRESHOLD)]: {
            overflow: 'unset',
        }
    },
    footerContainer: {
        padding: 32
    }
}));

export default useStyles