import React from 'react'
import { Switch } from '@mui/material'
import { makeStyles } from '@mui/styles'
import * as GreyScale from '../Theme/GreyScale'

const useStyles = makeStyles(theme => ({
  switch: {

    // ----------
    // -- ROOT --
    // ----------

    width: 77,
    height: 32,
    padding: 0,

    // ----------
    // -- MISC --
    // ----------

    "& .MuiSwitch-switchBase": {
      height: `100%`, // Aligns the thumb to the center of the track...

      // :hover "aura" color...
      '&:hover': {
        backgroundColor: 'unset',
      },

      // Unchecked thumb position...
      transform: "translateX(-6.5px)",

      "&.Mui-checked": {
        // Checked thumb position...
        transform: "translateX(39px)",
      },
    },

    // -----------
    // -- TRACK --
    // -----------

    // Track common...
    '& .MuiSwitch-track': {
      width: `100%`,
      height: `100%`,
      borderRadius: 25,
    },

    // Unchecked track...
    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
      backgroundColor: GreyScale.GREY_2,
      opacity: 'unset',
    },

    // Checked track...
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.action.main,
      opacity: 'unset',
    },

    // -----------
    // -- THUMB --
    // -----------

    // Thumb common...
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 26,
      height: 26,

      '&:hover': {
        boxShadow: 'pink',
      }
    },

    // Unchecked thumb...
    '& .MuiSwitch-input:not(:checked) + .MuiSwitch-thumb': {
      backgroundColor: GreyScale.GREY_1,
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.727502 2.64126L2.66271 0.727295L15.2727 13.1989L13.3375 15.1129L0.727502 2.64126Z' fill='black'/%3E%3Cpath d='M2.6625 15.2727L0.727295 13.3588L13.3373 0.887193L15.2725 2.80115L2.6625 15.2727Z' fill='black'/%3E%3C/svg%3E%0A")`,
      backgroundSize: 16,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    },

    // Checked thumb...
    '& .MuiSwitch-input:checked + .MuiSwitch-thumb': {
      backgroundColor: GreyScale.GREY_1,
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.54826 6.54996L2.4778 6.4795L2.40709 6.54971L1.20224 7.74608L1.13103 7.81679L1.20199 7.88775L5.94503 12.6308L6.01574 12.7015L6.08645 12.6308L16.2683 2.44896L16.339 2.37825L16.2683 2.30754L15.0719 1.11117L15.0012 1.0405L14.9305 1.11114L6.01577 10.0175L2.54826 6.54996Z' fill='black' stroke='black' stroke-width='0.2'/%3E%3C/svg%3E%0A")`,
      backgroundSize: 16,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    },

  },
}))

const QuantifeelSwitch = (props) => {

  const classes = useStyles();

  return (
    <Switch
      className={classes.switch}
      {...props}/>
  )
}

export default QuantifeelSwitch