import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Typography } from '@mui/material'
import translations from '../../../translations/en.json';
import useStyles from './Styles.js';
import {
  SST_PAGE_LIST_LINE_CONFIGURATION,
  SST_PAGE_LIST_RUNS,
  SST_PAGE_LIST_LINES,
  SST_PAGE_EDIT_LINE,
  SST_PAGE_LINE_SETTINGS,
  SST_PAGE_CREATE_MACHINE_CENTER,
} from '../../../Constants'
import { useQuery } from 'react-query'
import { getLine, getLineAreas, getRunsForLineArea, getTagsForLineArea } from '../../../query/queries'
import PageHeader from '../../../shared/components/PageHeader/PageHeader'
import * as React from 'react'
import ProgressButton, { PROGRESS_BUTTON_VARIANTS } from '../../../shared/components/ProgressButton/ProgressButton'
import FullScreenCircularProgress from '../../../shared/components/FullScreenCircularProgress'
import jimp from 'jimp'
import {
  DATE_TIME_TIMEZONE_FORMAT,
  handlePermissionRedirect,
  PERMISSION_METHOD_GET,
} from '../../../shared/Utilities'
import { SiteContext } from '../../../Context'
import QuantifeelSvgIcon from '../../../shared/components/QuantifeelSvgIcon/QuantifeelSvgIcon'
import {ReactComponent as EditIcon} from '../../../img/icons/edit.svg'
import {ReactComponent as SettingsIcon} from '../../../img/icons/settings.svg'
import EnhancedTable from '../../../shared/components/EnhancedTable/EnhancedTable'
import ProtectedMoment from '../../../shared/components/ProtectedMoment/ProtectedMoment'
import { getMachineCenterRuns, getMachineCenters } from '../../../query/entities/machineCenters'
import { getControlPoints } from '../../../query/entities/controlPoints'
import ActiveStatusIndicator from '../../../shared/components/ActiveStatusIndicator/ActiveStatusIndicator'
import CopyLabel from '../../../shared/components/CopyLabel/CopyLabel'
import CreateButton from '../../../shared/components/CreateButton'
import {filterAreas} from "../../../shared/AreaUtilities";

const pageTitle = translations.pages.viewLine.viewLine;

const LAST_RUN_UPLOAD_KEY = 'latestRunUpload';
const NUMBER_OF_TAGS_KEY = 'numberOfTags';
const CONTROL_POINTS_KEY = 'controlPoints';

const acceptablePagePermission = [
  {entity: 'Line', method: PERMISSION_METHOD_GET, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const AREA_COLUMN_KEYS = {
  AREA: "area",
  LAST_RUN_UPLOAD: "lastRunUpload",
  NUMBER_OF_TAGS: "numberOfTags",
  ACTIVE_STATUS: "activeStatus"
};

const areaColumns = [
  {id: AREA_COLUMN_KEYS.AREA, label: translations.common.areas.area, width: "500px", columnWidth: "500px"},
  {id: AREA_COLUMN_KEYS.LAST_RUN_UPLOAD, label: translations.common.lastRunUpload, columnWidth: "400px"},
  {id: AREA_COLUMN_KEYS.NUMBER_OF_TAGS, label: translations.pages.viewLine.numberOfTags},
  // {id: AREA_COLUMN_KEYS.ACTIVE_STATUS, label: translations.common.status}
]

const MACHINE_CENTER_COLUMN_KEYS = {
  MACHINE_CENTER: "machineCenter",
  LAST_RUN_UPLOAD: "lastRunUpload",
  NUMBER_OF_CONTROL_POINTS: "numberOfControlPoints",
  ACTIVE_STATUS: "activeStatus"
};

const machineCenterColumns = [
  {id: MACHINE_CENTER_COLUMN_KEYS.MACHINE_CENTER, label: translations.common.machineCenters.machineCenter, width: "500px", columnWidth: "500px"},
  {id: MACHINE_CENTER_COLUMN_KEYS.LAST_RUN_UPLOAD, label: translations.common.lastRunUpload, columnWidth: "400px"},
  {id: MACHINE_CENTER_COLUMN_KEYS.NUMBER_OF_CONTROL_POINTS, label: translations.common.machineCenters.numControlPoints},
  {id: MACHINE_CENTER_COLUMN_KEYS.ACTIVE_STATUS, label: translations.common.status}
]

const ViewLine = (props) => {

  const {
    history
  } = props;

  const { userDetails, hasPermission } = useContext(SiteContext);

  const match = useRouteMatch();
  const lineId = match.params.lineId;

  const [linemapImage, setLineMapImage] = useState(null);
  const [isLoadingLinemapImage, setIsLoadingLinemapImage] = useState(true);
  const linemapImageContainer = useRef(null)
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfRequestsInFlight, setNumberOfRequestsInFlight] = useState(0);

  // ------------------------------
  // -- BEGIN useQuery / useMemo --
  // ------------------------------

  const {isLoading: isLoadingLine, data: line= {}, refetch: refetchLine } = useQuery(
    ['line', {lineId: lineId}, {includeLinemapImageUrl: true}],
    getLine,
    {enabled: !!lineId}
  );

  const {isLoading: isLoadingAreas, data: areas = [] } = useQuery(
    ['areas', {lineId: lineId}],
    getLineAreas,
    {enabled: !!lineId}
  );

  const {isLoading: isLoadingMachineCenters, data: machineCenters = [] } = useQuery(
    ['machineCenters', {lineId: lineId}],
    getMachineCenters,
    {enabled: !!lineId}
  );

  /**
   * Given changes to areas (returned from API request),
   * Maintain a copy of areas (returned from API request), for purposes of enriching with run and tag data...
    */
  const enrichedAreas = useMemo(() => {
    return [...areas];
  }, [areas])

  /**
   * Given changes to machineCenters (returned from API request),
   * Maintain a copy of machineCenters (returned from API request), for purposes of enriching with additional data...
   */
  const enrichedMachineCenters = useMemo(() => {
    return [...machineCenters];
  }, [machineCenters])

  // ----------------------------
  // -- END useQuery / useMemo --
  // ----------------------------

  const imageUrl = line.linemapImageUrl;

  // ----------------------
  // -- BEGIN useEffects --
  // ----------------------

  /**
   * On component mount...
   */
  useEffect(() => {

    // Validate permissions...
    handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission)

    // Set page title..
    document.title = pageTitle;

    // Refetch line as it could've had been updated.
    refetchLine();

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Given changes to loading states,
   * Set isLoading...
   */
  useEffect(() => {
    if ( isLoadingLine ||
         isLoadingAreas ||
         isLoadingMachineCenters ||
         isLoadingLinemapImage ||
         numberOfRequestsInFlight > 0 ) {
      setIsLoading(true)
    }
    else {
      setIsLoading(false)
    }
  }, [isLoadingLine, isLoadingAreas, isLoadingMachineCenters, isLoadingLinemapImage, numberOfRequestsInFlight])

  /**
   * Given changes to line,
   * Parse lineMapImageUrl and set lineMapImage...
   */
  useEffect(() => {

    if (!!line) {

      if (imageUrl) {
        jimp
          .read({
            url: imageUrl,
          })
          .then((image) => {
            image.autocrop({
              leaveBorder: 40,
              cropOnlyFrames: false,
            })
            image.getBase64Async(jimp.MIME_PNG).then((base64) => {
              setLineMapImage(base64)
              setIsLoadingLinemapImage(false)
            })
          })
          .catch((e) => {
            setLineMapImage('')
            setIsLoadingLinemapImage(false)
          })
      }

    }
  }, [line]) // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Given changes to lineId, enrichedAreas,
   * Get runs and tags for each enrichedArea, and enrich areas with run and tag data...
   */
  useEffect(() => {

    // Validate lineId...
    if (!lineId) {
      return;
    }

    // For each enrichedArea, get runs and tags, and enrich areas with run and tag data...
    enrichedAreas.forEach((enrichedArea) => {

      // Update numberOfRequestsInFlight, for loading indicator purposes...
      setNumberOfRequestsInFlight((previousState) => previousState + 2) // One request for runs, one request for tags = 2 requests in flight...

      // ---------------------
      // -- LAST RUN UPLOAD --
      // ---------------------

      // Get runs for line area...
      getRunsForLineArea({ queryKey: ['runsForLineArea', { lineId: lineId, areaId: enrichedArea.id }] })
        .then((runsForLineArea) => {
          enrichedArea[LAST_RUN_UPLOAD_KEY] = runsForLineArea[0]?.dateRecorded // Runs are sorted by dateRecorded DESC via API, so first run is latest run...
        })
        .finally(() => {
          // Decrement numberOfRequestsInFlight, for loading indicator purposes...
          setNumberOfRequestsInFlight((previousState) => previousState - 1 );
        })

      // --------------------
      // -- NUMBER OF TAGS --
      // --------------------

      // Get tags for line area...
      getTagsForLineArea({ queryKey: ['tagsForLineArea', { lineId: lineId, areaId: enrichedArea.id }] })
        .then((tagsForLineArea) => {
          enrichedArea[NUMBER_OF_TAGS_KEY] = tagsForLineArea.length;
        })
        .finally(() => {
          // Decrement numberOfRequestsInFlight, for loading indicator purposes...
          setNumberOfRequestsInFlight((previousState) => previousState - 1);
        })

    })

  }, [lineId, enrichedAreas])

  /**
   * Given changes to lineId, machineCenters,
   * Get data each enrichedMachineCenter, and enrich machine centers with that data...
   */
  useEffect(() => {

    // Validate lineId...
    if (!lineId) {
      return;
    }

    // For each enrichedArea, get runs and tags, and enrich areas with run and tag data...
    enrichedMachineCenters.forEach((enrichedMachineCenter) => {

      // ---------------------
      // -- LAST RUN UPLOAD --
      // ---------------------

      // Get latest Run.dateRecorded, for Runs associated to machine center, and enrich...
      // a. Update numberOfRequestsInFlight, for loading indicator purposes...
      setNumberOfRequestsInFlight((previousState) => previousState + 1) // One request for runs means + 1 requests in flight...

      // b. Get runs for machine center, get latest Run.dateRecorded...
      getMachineCenterRuns({ queryKey: ['machineCenterRuns', { machineCenterId: enrichedMachineCenter.id }] })
        .then((machineCenterRuns) => {
          enrichedMachineCenter[LAST_RUN_UPLOAD_KEY] = machineCenterRuns[0]?.dateRecorded // Runs are sorted by dateRecorded DESC via API, so first run is latest run...
        })
        .finally(() => {
          // Decrement numberOfRequestsInFlight, for loading indicator purposes...
          setNumberOfRequestsInFlight((previousState) => previousState - 1 );
        })

      // --------------------
      // -- CONTROL POINTS --
      // --------------------

      // Get control points for machine center, and enrich...
      // a. Update numberOfRequestsInFlight, for loading indicator purposes...
      setNumberOfRequestsInFlight((previousState) => previousState + 1) // One request for control points means + 1 requests in flight...

      // b. Get control points for machine center...
      getControlPoints({ queryKey: ['machineCenterControlPoints', { machineCenterId: enrichedMachineCenter.id }] })
        .then((machineCenterControlPoints) => {
          enrichedMachineCenter[CONTROL_POINTS_KEY] = machineCenterControlPoints
        })
        .finally(() => {
          // Decrement numberOfRequestsInFlight, for loading indicator purposes...
          setNumberOfRequestsInFlight((previousState) => previousState - 1 );
        })

    })

  }, [lineId, enrichedMachineCenters])

  // --------------------
  // -- END useEffects --
  // --------------------

  const generateAreasTableRows = (areas) => {
    return filterAreas(areas, line).map((area) => {
      return {

        [AREA_COLUMN_KEYS.AREA]:
          <CopyLabel value={area.id} rawval={area.name?.toLowerCase()}>
            <Typography className={classes.textOverflowHiddenEllipsis}>
              {area.name}
            </Typography>
          </CopyLabel>,

        [AREA_COLUMN_KEYS.LAST_RUN_UPLOAD]:
          <ProtectedMoment
            date={area[LAST_RUN_UPLOAD_KEY]}
            format={DATE_TIME_TIMEZONE_FORMAT}
            rawval={area[LAST_RUN_UPLOAD_KEY]}
            tz={userDetails.user.tz}
          />,

        [AREA_COLUMN_KEYS.NUMBER_OF_TAGS]:
            area[NUMBER_OF_TAGS_KEY],

        // [AREA_COLUMN_KEYS.ACTIVE_STATUS]:
        //   <ActiveStatusIndicator
        //     rawval={area.inactive ? "inactive" : "active"} // To enable sorting...
        //     isActive={!area.inactive}
        //   />,
      }
    });
  }

  const generateMachineCentersTableRows = (machineCenters) => {
    return machineCenters.map((machineCenter) => {
      return {

        [MACHINE_CENTER_COLUMN_KEYS.MACHINE_CENTER]:
          <CopyLabel value={machineCenter.id} rawval={machineCenter.name?.toLowerCase()}>
            {/*<QuantifeelTooltip title={translations.common.machineCenters.viewMachineCenter}>*/}
            {/*  <Link*/}
            {/*    className={classes.textOverflowHiddenEllipsis}*/}
            {/*    to={`/${SST_PAGE_VIEW_MACHINE_CENTER}/${machineCenter.id}`}>*/}
            {/*    {machineCenter.name}*/}
            {/*  </Link>*/}
            {/*</QuantifeelTooltip>*/}
            <Typography className={classes.textOverflowHiddenEllipsis}>
              {machineCenter.name}
            </Typography>
          </CopyLabel>,

        [MACHINE_CENTER_COLUMN_KEYS.LAST_RUN_UPLOAD]:
          <ProtectedMoment
            date={machineCenter[LAST_RUN_UPLOAD_KEY]}
            format={DATE_TIME_TIMEZONE_FORMAT}
            rawval={machineCenter[LAST_RUN_UPLOAD_KEY]}
            tz={userDetails.user.tz}
          />,

        [MACHINE_CENTER_COLUMN_KEYS.NUMBER_OF_CONTROL_POINTS]:
          machineCenter[CONTROL_POINTS_KEY]?.length,

        [MACHINE_CENTER_COLUMN_KEYS.ACTIVE_STATUS]:
          <ActiveStatusIndicator
            rawval={machineCenter.inactive ? "inactive" : "active"} // To enable sorting...
            isActive={!machineCenter.inactive}
          />,

      }
    });
  }

  // --------------------------
  // -- BEGIN event handlers --
  // --------------------------

  const onBack = () => {
    // If there is a page in history, go back, else, go to /list-lines/${lineId}...
    return !!(history.location.key) ? history.goBack() : history.push(`/${SST_PAGE_LIST_LINES}`)
  }

  // ------------------------
  // -- END event handlers --
  // ------------------------

  // -------------------
  // -- BEGIN renders --
  // -------------------

  const classes = useStyles();

  const renderHeader = () => {

    const contentRight = (
      <div className={classes.headerButtonContainer}>

        {/* Runs List */}
        <ProgressButton
          variant={PROGRESS_BUTTON_VARIANTS.ACTION}
          text={translations.pages.viewLine.runsList}
          onClick={() => history.push(`/${SST_PAGE_LIST_RUNS}?lineId=${lineId}`)}
        />

        {/* Configuration Details */}
        <ProgressButton
          variant={PROGRESS_BUTTON_VARIANTS.ACTION}
          text={translations.pages.viewLine.configurationDetails}
          onClick={() => history.push(`/${SST_PAGE_LIST_LINE_CONFIGURATION}?lineId=${lineId}`)}
        />

      </div>
    )

    return (
      <PageHeader
        onBack={onBack}
        pageTitle={`${line.name} ${translations.pages.viewLine.info}`}
        contentRight={contentRight}
      />
    )
  }

  const renderAreasTable = () => {
    return (
      <div className={classes.tableContainer}>
        <Typography variant='h6'>{translations.common.areas.areas}</Typography>
        <EnhancedTable
          order={'asc'}
          orderBy={AREA_COLUMN_KEYS.AREA}
          rows={generateAreasTableRows(enrichedAreas)}
          headCells={areaColumns}
          isLoading={isLoadingAreas}
          enableFilter={false}
        />
      </div>
    )
  }

  const renderMachineCentersTable = () => {
    return (
      <div className={classes.tableContainer}>

        <div className={classes.tableContainerHeader}>

          <Typography variant='h6'>{translations.common.machineCenters.machineCenters}</Typography>

          {hasPermission('Line', 'insert') &&
            <div>
              <CreateButton
                onClick={() => history.push(`/${SST_PAGE_CREATE_MACHINE_CENTER}?lineId=${line.id}`)}
                isLoading={isLoading}
                label={translations.common.machineCenters.createMachineCenter}
              />
            </div>
          }

        </div>

        <EnhancedTable
          order={'asc'}
          orderBy={MACHINE_CENTER_COLUMN_KEYS.MACHINE_CENTER}
          rows={generateMachineCentersTableRows(enrichedMachineCenters)}
          headCells={machineCenterColumns}
          isLoading={isLoadingAreas}
          enableFilter={false}
        />
      </div>
    )
  }

  return (
    <>
      {isLoading && <FullScreenCircularProgress/>}

      <div className={classes.root}>

        {renderHeader()}

          <div className={classes.lineDetailsStyle}>
            <div className={classes.lineHeadingStyle}><Typography className={classes.lineDetailsHeader}>Line</Typography></div>
            <div className={classes.lineHeadingStyle}><Typography className={classes.lineDetailsHeader}>Plant</Typography></div>
            <div className={classes.lineHeadingStyle}><Typography className={classes.lineDetailsHeader}>Current Version</Typography></div>
            <div className={classes.lineHeadingStyle} style={{flex: .5}}/> { /* Override flex, from 1 to .5, to ensure actions element take up less real estate. */ }

          </div>
          <div className={classes.lineDetailsStyle}>
            <div className={classes.lineHeadingStyle}><Typography>{line.name}</Typography></div>
            <div className={classes.lineHeadingStyle}><Typography>{line.plantName}</Typography></div>
            <div className={classes.lineHeadingStyle}><Typography>{line.highestConfigurationVersion}</Typography></div>
            <div className={classes.lineHeadingStyle} style={{flex: .5}}> { /* Override flex, from 1 to .5, to ensure actions element take up less real estate. */ }

              <div className={classes.lineDetailsActionsContainer}>
                <Link to={`/${SST_PAGE_EDIT_LINE}?lineId=${line.id}`}>
                  <QuantifeelSvgIcon
                    component={EditIcon}
                    viewBox="0 0 32 32"
                    tooltipTitle={translations.common.lines.editLine}
                  />
                </Link>
                <Link to={`/${SST_PAGE_LINE_SETTINGS}/${line.id}`}>
                  <QuantifeelSvgIcon
                    component={SettingsIcon}
                    viewBox="0 0 32 32"
                    style={{fill: 'black'}}
                    tooltipTitle={translations.common.lines.lineSettings}
                  />
                </Link>
              </div>

            </div>
          </div>
        {!!linemapImage &&
          <div
          ref={linemapImageContainer}
          className={classes.linemapImageContainer}
          style={{ backgroundImage: `url(${linemapImage})` }}
        />}

        <div className={classes.tablesContainer}>
          {renderAreasTable()}
          {renderMachineCentersTable()}
        </div>

      </div>

    </>
  );
}

export default ViewLine;