import React from 'react'
import { makeStyles } from '@mui/styles'
import { SvgIcon } from '@mui/material'
import {ReactComponent as AddIcon} from '../../img/icons/add_circle.svg'
import ProgressButton, { PROGRESS_BUTTON_VARIANTS } from './ProgressButton/ProgressButton'

const CreateButton = (props) => {

  const {
    isLoading,
    onClick,
    label
  } = props;

  const useStyles = makeStyles(theme => ({
    buttonContentContainer:{
      display: 'flex',
    },
    buttonIcon: {
      marginRight: theme.spacing(1),
      '& > *': {
        fill: `white`,
      }
    }
  }));

  const classes = useStyles();

  return (
    <ProgressButton
      variant={PROGRESS_BUTTON_VARIANTS.ACTION}
      isLoading={isLoading}
      onClick={onClick}
      leadingContent={
        <SvgIcon
          component={AddIcon}
          viewBox={'0 0 24 24'}
          className={classes.buttonIcon}
          onClick={onClick}
        />
      }
      text={label}
    />
  )
}

export default CreateButton