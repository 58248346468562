import { makeStyles } from '@mui/styles'
import tinyColor from 'tinycolor2'

const useStyles = makeStyles(theme => ({
  roleSelector: {
    maxWidth: 325,
    minWidth: 150,
    marginRight: 12,
  },
  row: {
    marginBottom: 15,
  },
  addButton: {
    float: 'right',
    color: 'white',
    backgroundColor: '#0E4DA4',
    marginRight: 16,
    '&:hover': {
      backgroundColor: tinyColor('#0E4DA4').lighten().toHexString(),
    },
  },
  head: {
    color: 'white'
  },
  toolbar: {
    marginBottom: 15,
    paddingLeft: 15,
  },
  newRoleRow: {
    backgroundColor: '#F6F6F6',
  },
  removeRoleButton: {
    color: '#F60000',
  },
  addRoleButton: {
    color: '#5CDB5C',
  },
  newRoleCell: {
    padding: '8px 16px',
  },
  roleDescription: {
    fontSize: '20px',
    height: '32px',
  },
  tooltip: {
    fontSize: '14px',
  },
}));

export default useStyles
