import { makeStyles } from '@mui/styles'
import * as GreyScale from '@Theme/GreyScale'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: '0.1rem',
    paddingRight: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '1rem',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  header: {
    display: 'flex',
    borderBottom: `1px solid ${GreyScale.GREY_4}`,
    maxHeight: '70px',
    position: 'sticky',
    alignContent: 'center',
  },
  backButton: {
    fontSize: '32px',
    color: GreyScale.GREY_4,
    padding: '8px',
    border: '1px solid black',
    borderRadius: '3px',
    cursor: 'pointer',
  },
  backButtonContainer: {
    width: '70px',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerLabelsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: theme.spacing(1),
    minWidth: 300,
    width: 300,

    '& > *':{
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
  },
  contentGrid: (contentGridHeight) => ({
    flex: 1,
    display: 'grid',
    gridTemplateRows: `repeat(2, ${(contentGridHeight / 2) - 1}px)`, // No sane row height values (e.g. fr) seem to work, so force it. - 1 to help w/ overflow, :(
    gridTemplateColumns: '0.5fr 1.00fr 1.00fr',
    gridGap: theme.spacing(1),
    marginTop: theme.spacing(1),
    padding: 1, // Help prevent unwanted overflow, :(
  }),
  divider: {
    alignSelf: 'center',
    height: '45px',
    width: '1px',
    backgroundColor: GreyScale.GREY_3,
    margin: '1rem 1rem',
  },
  runSelector: {
    position: 'relative',
    padding: '8px',
    margin: 'auto',
    width: '100%',
    height: '65px',
  },
  plantName: {
    marginLeft: '19px',
    fontSize: '33px',
    lineHeight: '40px',
    fontWeight: '600',
  },
  lineName: {
    marginLeft: '19px',
    fontSize: '20px',
    lineHeight: '24px',
  },
  select: {
    width: '300px',
    marginRight: '1rem',
  },
  linemapImageContainer: {
    flex: 1,
    display: 'flex',
    backgroundColor: '#f4f4f4', // Ignoring MUI color pallette for this, needs to exact match linemap image color.
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  linemapImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',

    '& > :not(:last-child)': {
      borderBottom: `1px solid ${GreyScale.GREY_4}`,
    },
  },
  detailHeader: {
    fontSize: '20px',
    lineHeight: '1.5rem',
    margin: '1rem 1rem',
  },
  detailRow: {
    display: 'flex',
    padding: '11px 16px',

    '& > *': {
      width: '50%',
      fontSize: '14px',
      lineHeight: '25px',
    },
  },
  detailRowCell: {
    overflowWrap: 'break-word',
  },
  gridCell: {
    display: 'flex'
  },
  lineRunChartControlsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  selectedTimeLabel: {
    border: '1px solid black',
    padding: 4.5,
    width: 150,
    textAlign: 'center',
  },
  lineRunChartHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid black',
    borderBottom: 'none',
    padding: theme.spacing(1),
  },
  lineRunChartContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  graphContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    border: '1px solid black',
    overflow: 'hidden', // LineRunStatistics stretches container, otherwise. Not sure why. :(
  },
  lineInfo: {
    display: 'flex',
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: GreyScale.GREY_1,
    opacity: 0.8,
  },
  arrowIcon: {
    fontSize: 34,
    cursor: 'pointer',
  }
}))

export default useStyles
