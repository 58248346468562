import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  dialog: {
    '& .MuiDialog-paper': {
      minWidth: 650,
      minHeight: 250,
    }
  },
  dialogTitleContainer:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  closeIcon: {
    cursor: 'pointer',
  },
  dialogContentContainer: {
    flex: 1,
    color: 'black',
    padding: theme.spacing(2),
  },
  dialogActionsContainer: {
    padding: theme.spacing(2),
  }
}));

export default useStyles