import React from "react";
import useStyles from './Styles.js';
import {ReactComponent as SmartskinLogo} from '../../../img/Smartskin_Stacked.svg'
import QauntifeelLogo from '../../../img/quantifeel_logo.png'
import Footer from '../Footer/Footer'
import QuantifeelSvgIcon from "../QuantifeelSvgIcon/QuantifeelSvgIcon";

const GiantLogoPageTemplate = (props) => {

    const {children, showFooter = true, showLogoInContent = true} = props;

    const classes = useStyles();

    return (
        <div className={classes.root} data-testid="login-page">

            <div className={classes.logoContainer}>
                <img className={classes.logo} src={QauntifeelLogo} alt={'quantifeelLogo'}/>
            </div>

            <div className={classes.contentContainer}>

                {showLogoInContent && <div className={classes.logoContainer}>
                    <QuantifeelSvgIcon className={classes.logo} component={SmartskinLogo} viewBox="0 0 450.21 300.01"/>
                </div>}

                <div className={classes.innerContentContainer}>
                    {children}
                </div>

                {showFooter &&
                    <div className={classes.footerContainer}>
                        <Footer useUpdatedFooter={false}/>
                    </div>
                }
            </div>

        </div>
    );
};

export default GiantLogoPageTemplate;