import { makeStyles } from '@mui/styles'
import { PROGRESS_BUTTON_VARIANTS } from './ProgressButton'
import * as GreyScale from '@Theme/GreyScale'

const useStyles = makeStyles(theme => ({
  button: {
    minWidth: 128,
    boxShadow: 'none',

    '&:hover': {
      boxShadow: 'none'
    },

    '&:disabled': {
      opacity: 0.5,
    },

    '& > p': {
      fontSize: `20px`,
      fontWeight: `500`,
      lineHeight: `24px`,
      textTransform: 'none',
    }
  },
  [PROGRESS_BUTTON_VARIANTS.ACTION]: {
    color: 'white',
    borderRadius: 0,
    backgroundColor: theme.palette.action.main,

    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },

    '&:disabled': {
      color: 'white',
      backgroundColor: theme.palette.action.main,
    }
  },
  [PROGRESS_BUTTON_VARIANTS.CANCEL]: {
    color: 'black',
    borderRadius: 0,
    backgroundColor: GreyScale.GREY_2,

    '&:hover': {
      backgroundColor: GreyScale.GREY_3,
    },

    '&:disabled': {
      color: 'black',
      backgroundColor: GreyScale.GREY_2,
    }
  },
  // [VARIANTS.START]: {},
  [PROGRESS_BUTTON_VARIANTS.ERROR]: {
    color: 'white',
    borderRadius: 0,
    backgroundColor: theme.palette.accent.main,

    '&:hover': {
      backgroundColor: theme.palette.accent.hover,
    },

    '&:disabled': {
      color: 'black',
      backgroundColor: theme.palette.accent.main,
    }
  }
}))

export default useStyles