import * as React from "react";
import {useContext, useEffect} from "react";
import {useQuery} from 'react-query';

import {Link, useHistory} from "react-router-dom";
import {SiteContext} from "../../Context";
import EnhancedTable from "../../shared/components/EnhancedTable/EnhancedTable";
import CopyLabel from "../../shared/components/CopyLabel/CopyLabel";
import { handlePermissionRedirect, PERMISSION_METHOD_GET, PERMISSION_METHOD_UPDATE } from "../../shared/Utilities";

import '../PageStyles/TablePage.css'

import {
  getAllCustomers,
  getCustomerTypes
} from '../../query/queries';
import CreateButton from "../../shared/components/CreateButton";

const pageTitle = 'List Customers';
const acceptablePagePermission = [
  {entity: 'CustomerUser', method: PERMISSION_METHOD_UPDATE, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const columns = [
  {id: "name", label: "Name", width: "390px"},
  {id: "customerType", label: "Customer Type", width: "250px"},
  {id: "relatedMultiSensors", label: "MultiSensors", width: "250px"},
  {id: "relatedPlants", label: "Plants", width: "250px"},
  {id: "actions", label: "Actions", width: "130px"}
];

const genRows = (customers, customerTypes) => {

  const types = customerTypes.reduce((memo, type) => {
    memo[type.id] = type.name;
    return memo;
  }, {});

  return customers.map(customer => ({
    name: <CopyLabel value={customer.id} rawval={customer.name.toLowerCase()}>{customer.name}</CopyLabel>,
    customerType: types[customer.customerTypeId] || <i rawval="Not Assigned">Not Assigned</i>,
    relatedMultiSensors: customer.relatedMultiSensors,
    relatedPlants: customer.relatedPlants,
    actions: <Link to={"/edit-customer?customerId=" + customer.id}>Edit customer</Link>
  }))
}

const ListAllCustomers = (props) => {

    const history = useHistory();
    const {setBreadcrumbs, hasPermission} = useContext(SiteContext);

    const {isLoading: isLoadingCustomers, data:customers=[]} = useQuery(['customers', {}, {detailed:true}], getAllCustomers);
    const {isLoading: isLoadingCustomerTypes, data:customerTypes=[]} = useQuery(['customerTypes'], getCustomerTypes);

    useEffect(() => {
      document.title = pageTitle;
      setBreadcrumbs([
        {
          title: pageTitle,
        }
      ]);
    }, [setBreadcrumbs])

    
    const onCreateButtonClick = (e) => {
        props.history.push("/create-customer");
    };

    return (
        <div className="page" data-testid="list-customers-page">
          {handlePermissionRedirect(pageTitle, history, hasPermission, acceptablePagePermission) &&
            <div className="container">
              {hasPermission('Customer', 'insert') && <div className="createButton">
                  <CreateButton onClick={onCreateButtonClick}
                                  isLoading={isLoadingCustomers || isLoadingCustomerTypes}
                                  label={"Create Customer"}/>
              </div>}
              <EnhancedTable
                  order={'asc'}
                  orderBy={'Name'}
                  rows={genRows(customers, customerTypes)}
                  headCells={columns}
                  isLoading={isLoadingCustomers || isLoadingCustomerTypes}
                  title="Customers"
                  enableFilter={true}
                  filterColumns={['name', 'customerType']}/>
            </div>
          }
        </div>
    );
};

export default ListAllCustomers;
