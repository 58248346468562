import React, {useContext, useEffect} from "react";
import {useQuery} from "react-query";
import {Link} from 'react-router-dom';
import {SiteContext} from "../../Context";
import {DATE_TIME_TIMEZONE_FORMAT, handlePermissionRedirect, PERMISSION_METHOD_GET, PERMISSION_METHOD_UPDATE} from "../../shared/Utilities";
import ProtectedMoment from '../../shared/components/ProtectedMoment/ProtectedMoment';
import EnhancedTable from "../../shared/components/EnhancedTable/EnhancedTable";
import CopyLabel from "../../shared/components/CopyLabel/CopyLabel";
import '../PageStyles/TablePage.css'

import {
  getSensorsByCustomer
} from '../../query/queries';
import CreateButton from "../../shared/components/CreateButton";

const pageTitle = 'List Multisensors';
const acceptablePagePermission = [
  {entity: 'Hub', method: PERMISSION_METHOD_UPDATE, modifier: ''},
  {entity: 'Customer', method: PERMISSION_METHOD_GET, modifier: 'children'}
]

const genRows = (sensors, details) => {

  return sensors.map(sensor => {
    return {
      name: <CopyLabel value={sensor.id} rawval={sensor.name.toLowerCase()}>{sensor.name}</CopyLabel>,
      macAddress: sensor.macAddress,
      plantName: sensor.plantName,
      dateCreated: <ProtectedMoment date={sensor["dateCreated"]} format={DATE_TIME_TIMEZONE_FORMAT} />,
      lastSeen: ("dateLastSeenRecord" in sensor)
        ? <div rawval={sensor["dateLastSeenRecord"]}>
          <ProtectedMoment format={DATE_TIME_TIMEZONE_FORMAT} date={sensor["dateLastSeenRecord"]} tz={details.user.tz} />
          <br />
          <i><ProtectedMoment from={sensor["dateLastSeenRecord"]} toNow ago /> ago</i>
        </div>
        : <div rawval={new Date(0).toISOString()}>Never</div>,
      actions: <div>
        <Link to={"/edit-sensor?id=" + sensor["id"]}>Details</Link>
        <br />
        <Link to={`/sensors/${sensor.id}/combined-vibration`}>Time Waveform and FFT</Link>
      </div>
    }
  })
};

const columns = [
  {id: "name", label: "Name", width: '390px'},
  {id: "macAddress", label: "MacAddress", width: '250px'},
  {id: "plantName", label: "Plant Name", width: '350px'},
  {id: "dateCreated", label: "Created", hidden: true, width: '175px'},
  {id: "lastSeen", label: "Last Seen", width: '210px'},
  {id: "actions", label: "Actions", width: '225px'}
];

const ListMultiSensors = (props) => {

    const {setBreadcrumbs, currentCustomer, hasPermission, userDetails} = useContext(SiteContext);

    const {isLoading: isLoadingSensors, data: sensors=[]} = useQuery(['sensors', {customerId: currentCustomer}, {detailed:true}], getSensorsByCustomer);

    useEffect(() => {
      document.title = pageTitle;
      setBreadcrumbs([{ title: pageTitle }]);        
    }, [setBreadcrumbs]);

    const onCreateButtonClick = (e) => {
        props.history.push("/create-sensor");
    };    

    return (
        <div className="page" data-testid="list-sensors-page">
          {handlePermissionRedirect(pageTitle, props.history, hasPermission, acceptablePagePermission) &&
            <div className="container">
              {hasPermission('Multisensor', 'insert') && <div className="createButton">
                  <CreateButton onClick={onCreateButtonClick}
                                  isLoading={isLoadingSensors}
                                  label={"Create Sensor"}/>
              </div>}
              <EnhancedTable
                title="MultiSensors"
                order={'asc'}
                orderBy={'Name'}
                rows={genRows(sensors, userDetails)}
                headCells={columns}
                isLoading={isLoadingSensors}
                enableFilter
                filterColumns={['name', 'customerName', 'macAddress']}/>
            </div>
          }
        </div>
    );
};

export default ListMultiSensors;
