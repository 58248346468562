import React from 'react'
import { Typography } from '@mui/material'
import QuantifeelRouterLink from '../QuantifeelRouterLink/QuantifeelRouterLink'
import translations from '../../../translations/en.json'
import { makeStyles } from '@mui/styles'
import { SST_COPYRIGHT_LABEL } from '../../../Constants'

const Footer = (props) => {

    let {useUpdatedFooter=true} = props;

    const useStyles = makeStyles(theme => ({

        root: {
            display: 'flex',
            width: '100%',
            flexDirection: useUpdatedFooter ? 'row' : 'column',
            alignItems: 'center',
        },
        linksContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center'
        },
        linksContainerOld: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            '& > :not(:last-child)': {
                paddingRight: 8,
                marginRight: 8,
                borderRight: '2px solid black'
            },
            marginBottom: 8
        },
        typography: {
            fontSize: 12,
            color: 'black',
            textDecoration: 'none',
            '&:hover': {
                color: theme.palette.accent.main,
                textDecoration: 'underline',
                textDecorationColor: theme.palette.accent.main,
            }
        },
        copyrightLabel: {
            color: 'black',
            fontStyle: 'italic',
            fontWeight: 'bold',
            fontSize: 12
        },
        copyrightLabelOld: {
            color: 'black',
            fontSize: 12,
        },
        separationLine: {
            paddingLeft: 8,
            marginLeft: 8,
            borderLeft: '1px solid black',
            height: '12px'
        }
    }));

    const classes = useStyles();

    return (
        <div className={classes.root}>
            {useUpdatedFooter ?
                <>
                    <div className={classes.copyrightContainer}>
                        <Typography className={`${classes.typographyOld} ${classes.copyrightLabel}`}>
                            {SST_COPYRIGHT_LABEL}
                        </Typography>
                    </div>
                    <div className={classes.separationLine}/>
                    <div className={classes.linksContainer}>
                        <QuantifeelRouterLink className={classes.link} to="/privacy">
                            <Typography className={classes.typography}>
                                {translations.components.footer.privacyPolicy}
                            </Typography>
                        </QuantifeelRouterLink>
                        <div className={classes.separationLine}/>
                        <QuantifeelRouterLink className={classes.link} to="/support">
                            <Typography className={classes.typography}>
                                {translations.components.footer.support}
                            </Typography>
                        </QuantifeelRouterLink>
                    </div>
                </>
                :
                <>
                <div className={classes.linksContainerOld}>
                        <QuantifeelRouterLink className={classes.link} to="/support">
                            <Typography className={classes.typography}>
                                {translations.components.footer.support}
                            </Typography>
                        </QuantifeelRouterLink>
                        <QuantifeelRouterLink className={classes.link} to="/privacy">
                            <Typography className={classes.typography}>
                                {translations.components.footer.privacyPolicy}
                            </Typography>
                        </QuantifeelRouterLink>
                    </div>
                    <div className={classes.copyrightContainer}>
                        <Typography className={classes.copyrightLabelOld}>
                            {SST_COPYRIGHT_LABEL}
                        </Typography>
                    </div>
                </>
            }
        </div>
    );
};

export default Footer;