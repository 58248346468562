import { makeStyles } from '@mui/styles'
import EnhancedTable from '../EnhancedTable/EnhancedTable'
import { truncateNumberToDecimals } from '../../Utilities'
import { getFlag } from './Helpers'

const flagTableColumns = [
  { id: 'flagId', hidden: true },
  { id: 'flagNumber' },
  { id: 'flagName' },
  { id: 'flagTimepoint' },
]

const tableTitle = 'Flags'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
  },
  flagTableText: {
    display: 'flex',
    fontSize: '14px',
    height: '5px',
    alignItems: 'center',
  },
  flagTableTitleText: {
    fontSize: 20,
    color: 'white',
  },
  flagTableHeaderRoot: {
    padding: `0 ${theme.spacing(2)}`,
    minHeight: '36px',
    maxHeight: '36px',
    backgroundColor: theme.palette.primary.main,
  },
}))

const FlagTable = (props) => {
  const { runFlags, isLoading, onRowClick, selectedFlag } = props

  const handleRowClick = (e, id, row) => {
    if (selectedFlag?.id === row.id) {
      onRowClick(undefined)
    } else {
      const selectedFlag = getFlag(runFlags, row.id)
      onRowClick(selectedFlag)
    }
  }

  const generateRows = (flags) => {
    return flags.map((flag, i) => {
      return {
        id: flag.id,
        flagNumber: `#${i + 1}`,
        flagName: flag.name,
        flagTimepoint: truncateNumberToDecimals(flag.time, 3),
      }
    })
  }

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <EnhancedTable
        title={tableTitle}
        styleTable={[
          classes.flagTableText,
          classes.flagTableTitleText,
          classes.flagTableHeaderRoot,
        ]}
        isLoading={isLoading}
        order={'asc'}
        orderBy={'name'}
        rows={generateRows(runFlags)}
        headCells={flagTableColumns}
        enableFilter={false}
        hasColumnTitles={false}
        selectedRow={selectedFlag}
        isSelectable
        onClick={handleRowClick}
        filterColumns={['flagId', 'flagNumber', 'flagName', 'flagTimepoint']}
        blackBorder
        squareCorners
        hasBoldNumbers
        pagination={false}
      />
    </div>
  )
}

export default FlagTable
