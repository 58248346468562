import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({

    supportFormContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    supportFormText: {
        marginBottom: 16,
    },
    supportForm: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
    },
    supportFormMessagesContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    supportFormField: {
        marginBottom: 16,
    },
    textArea: {
        minHeight: 100
    },
    supportFormSubmitButton: {
        width: '100%',
        margin: '8px 0',
    },
}));

export default useStyles