import React from 'react'
import { Radio } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  radio: {
    '&.Mui-checked': {
      color: theme.palette.action.main,
    },
    '&.Mui-disabled': {
      opacity: 0.5,
    },
  },
}))

const QuantifeelRadio = (props) => {

  const classes = useStyles();

  return (
    <Radio
      className={classes.radio}
      {...props}/>
  )
}

export default QuantifeelRadio