import { makeStyles } from '@mui/styles'
import * as GreyScale from '@Theme/GreyScale'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  readOnlyNotificationContainer: {
    padding: 26,
    paddingBottom: 0,
  },
  metadataTableContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',

    padding: `26px`,
    // borderTop: `1px solid black`,

    '& > :not(:last-child)': {
      marginBottom: '26px'
    }
  },
  metadataTableHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  metadataTable: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: `0px 26px`,
  },
  metadataTableForm: {

    '& > :first-child)': {
      borderTop: `1px solid ${GreyScale.GREY_1}`,
    },

    '& > *': {
      borderBottom: `1px solid ${GreyScale.GREY_1}`
    }
  },
  metadataTableRow: {
    width: '100%',
    height: theme.spacing(8),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '& > :not(:last-child)': {
      marginRight: theme.spacing(2)
    }
  },
  metadataCell: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'start',
  },
  metadataDataCell: {
    justifyContent: 'center',
    padding: `0px ${theme.spacing(2)}`,
  },
  metadataCellInput: {
    width: '100%',
    '&.Mui-disabled': {
      opacity: 1
    },
  },
  metadataActionsCell: {
    width: theme.spacing(10),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  metadataErrorMessageContainer: {
    flex: 1,
    marginTop: '12px'
  },
  metadataErrorMessage: {
    padding: '0px 14px',
    fontSize: '10px',
    color: 'red'
  },
  deleteIcon: {
    fontSize: 20,
    cursor: 'pointer',

    '& > *': {
      fill: theme.palette.accent.main,
    }
  },
  footerContentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerContentContainerSubContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > :not(:last-child)': {
      marginRight: theme.spacing(5),
    }
  },
  dialogActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',

    '& > :not(:last-child)': {
      marginRight: theme.spacing(2),
    }
  },

  // -------------
  // -- AddIcon --
  // -------------

  addIcon: {
    fontSize: 48,
    cursor: 'pointer',
  }

}));

export default useStyles
